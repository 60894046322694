<template>
  <div ref="trigger" :class="{ visible: isVisible }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      observer: null,
      option: {
        root: null,
        threshold: 0.3,
      },
      isVisible: false, // 가시성 상태를 반응형으로 관리
    };
  },
  methods: {
    handleIntersect(entries) {
      const target = entries[0];
      if (target.isIntersecting && !this.isVisible) {
        this.isVisible = true; // 상태 업데이트
        this.$emit("triggerFadeIn");
      }
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.handleIntersect, this.option);
    this.observer.observe(this.$refs.trigger);
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect(); // Observer 해제
    }
  },
};
</script>

<style scoped>
div {
  opacity: 0;
  transition: opacity 1s;
}

div.visible {
  opacity: 1;
}
</style>
