import { createApp } from 'vue'
import { createRouter, createWebHistory } from "vue-router";
import { initSentry } from '@/api/sentry-init';
import App from './App.vue'
import store from "@/tarot_box/helper/store";
import utils from "@/tarot_box/helper/utils";
import Constants from "@/tarot_box/pages/box_input/assets/constants";
import Vue3TouchEvents from "vue3-touch-events";
import VWave from "v-wave";
import vueDebounce from 'vue-debounce';
import {LoadingPlugin} from 'vue-loading-overlay';
import {isNewAppVersion} from "@/utils/appVersion";
import AppListenerPlugin from '@/common/app_listener_plugin';
import mitt from "mitt";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import AppCommanderPlugin from "@/common/app_commander_plugin";
import {TarotBoxRoutes} from "@/tarot_box/router"
import Hotjar from "@hotjar/browser";

const router = createRouter({
    history: createWebHistory(),
    routes: TarotBoxRoutes,
});

router.beforeEach((to, from, next) => {
    to.meta.fromPath = from.fullPath;
    next();
});

// vh 단위 문제 해결
export function updateVH() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

updateVH();

const app = createApp(App);

initSentry(app, router);


const eventBus = mitt();

//웹마이타로 분기처리 전역 디렉티브, 데이터
const WebDirective = {
    beforeMount(el) {
        const params = new URLSearchParams(window.location.search);
        const isWeb = params.get('is_web');
        const osType = params.get('os_type');
        if (isWeb !== 'true' && osType !== 'web') {
            el.style.display = 'none';
        }
    }
};
const AppDirective = {
    beforeMount(el) {
        const params = new URLSearchParams(window.location.search);
        const isWeb = params.has('is_web');
        const osType = params.get('os_type');
        if (isWeb || osType === 'web') {
            el.style.display = 'none';
        }
    }
};

app.directive('focus', {
    inserted: function (el) {
        el.focus()
    }
})

//앱빌드 83번 이상 상하단 패딩 확보
export const vSafeAreaTop = {
    mounted(el) {
        if (isNewAppVersion()) {
            const urlParams = new URLSearchParams(window.location.search);
            const insetTop = parseInt(urlParams.get('inset_top') || '0', 10);

            if (insetTop > 0) {
                el.style.paddingTop = `calc(${el.style.paddingTop || '0px'} + ${insetTop}px)`;
            }
        }
    }
};


export const vSafeAreaBottom = {
    mounted(el) {
        if (isNewAppVersion()) {
            const urlParams = new URLSearchParams(window.location.search);
            const insetBottom = parseInt(urlParams.get('inset_bottom') || '0', 10);

            if (insetBottom > 0) {
                el.style.paddingBottom = `calc(${el.style.paddingBottom || '0px'} + ${insetBottom}px)`;
            }
        }
    }
};

app.config.globalProperties.$isWeb = () => {
    const params = new URLSearchParams(window.location.search);
    const isWeb = params.get('is_web') === 'true';
    const osType = params.get('os_type') === 'web';
    return isWeb || osType;
};

app.config.errorHandler = (err, vm, info) => {
    if (err instanceof TypeError && err.message.includes("null is not an object (evaluating '_.value.contains')")) {
        console.warn('Ignored TypeError from vue-bottom-sheet:', err.message);
        return;
    }
    if (err instanceof TypeError && err.message.includes("reading 'contains'")) {
        console.warn('Ignored TypeError from vue-bottom-sheet:', err.message);
        return;
    }
    // 다른 에러 처리
    console.error('Unhandled error:', err, info);
};

export const initHotjar = () => {
    const siteId = 5239207;
    const hotjarVersion = 6;
    Hotjar.init(siteId, hotjarVersion);
    console.log('Hotjar initialized');
};


app.directive('web', WebDirective);
app.directive('app', AppDirective);
app.directive('safe-area-top', vSafeAreaTop)
app.directive('safe-area-bottom', vSafeAreaBottom)

app.use(router);
app.use(store);
app.use(utils);
app.provide("eventBus", eventBus);
app.use(Constants);
app.use(Vue3TouchEvents,{
    swipeTolerance: 50,
});
app.use(VWave, {
    color: '#000000',
    initialOpacity: 0.3,
    duration: 0.3,
    easing: 'ease-in',
});
app.directive('debounce', vueDebounce({ lock: true, defaultTime: '500ms' }));

app.use(LoadingPlugin);
app.use(AppListenerPlugin);
app.use(AppCommanderPlugin);

app.mount("#app");

console.info('app: ', app);

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
