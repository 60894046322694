<template>
  <div class="component-container "
       v-safe-area-top
       :style="{
      padding: padding,
      backgroundImage: `url(${tarotBoxHistory.output_page_meta_data.background_image_url})`,
      backgroundColor: backgroundColor}">
    <div class="content-wrapper">
      <div class="heading-texts">
        <img id="symbol" :src="tarotBoxHistory.output_page_meta_data.objet_icon_image_url" alt="symbol"/>
        <span class="font14 gray5">
          <strong>부록</strong>
        </span>
        <span class="serif gray1 font20">속시원한 심층풀이 ({{currentAnswerIndex}}/{{totalExtraAnswerCount}})</span>
      </div>
      <div class="extra-listings">
        <div class="content-texts serif gray4 questions">
          <span style="margin-bottom: 12px;"><strong>질문</strong></span>
          {{currentPageData.page_content.extra_question}}
        </div>
        <div class="content-texts serif gray4 questions">
          <span style="margin-bottom: 12px;"><strong>풀이</strong></span>
          <div v-html="formattedContent"/>
        </div>
      </div>
    </div>

    <!-- 추가 질문 -->
    <div class="more-question" ref="moreQuestion" v-if="!isShare && totalExtraAnswerCount === currentAnswerIndex">
      <div class="actual-text">
        <div class="actual-title-text serif font20 gray3">
          <span>궁금한 점이 남으셨나요?</span>
        </div>
        <div class="actual-sub-text serif font14 gray4">
          <span style="font-size: 22px;"><strong>질문을 더 풀이해 드릴게요</strong></span>
        </div>
      </div>

      <div class="text-field">
      <textarea
          ref="textareaRef"
          :value="question"
          @input="handleInput"
          @click="preventClick"
          @focus="handleFocus"
          @blur="handleBlur"
          class="text-field serif font18"
          maxlength="200"
          :placeholder="placeholderText"
          :disabled="isInterpreting"
          :readonly="isInterpreting"
      ></textarea>
        <div class="text-length">
          {{ textLength }}/200
        </div>
      </div>
      <transition name="slide-fade">
        <div
            v-if="!isWriting"
            v-wave
            class="request-button button-box"
            @click="handleButtonClick"
            :class="{ 'interpreting': isInterpreting || requestSuccessful , 'disabled': !isButtonEnabled }">
          {{ isInterpreting ? '해석 중' : '해석받기' }}

          <LoaderImage color="var(--loaderImage)"
                       v-if="isInterpreting"
                       style="margin-left: 8px;"
                       :size="12"
                       :speed="0.6"
                       :borderWidth="10" />
        </div>
      </transition>
    </div>
  </div>

  <DialogPopup
      :isActive="isPopupShown"
      :closePopupCallback="() => { isPopupShown = false }"
      :request-successful="requestSuccessful"
      :user-data="userData"
      :extraQuestionStatus="extraQuestionStatus"
      :isPaid="true"
      @request="$emit('request-more')"
      @close="isPopupShown = false"/>

</template>
<script>
import { marked } from 'marked';
import {markReadExtraAnswer} from "@/tarot_box/helper/mytarot_api";
import {logEvent} from "@/tarot_box/helper/native_api";
import LoaderImage from "@/tarot_box/components/LoaderImage.vue";
import DialogPopup from "@/tarot_box/pages/box_result_v2/components/Popups/DialogPopup.vue";

export default {
  name: "ExtraAnswerComponent",
  components: {DialogPopup, LoaderImage},
  props: {
    currentIndex: {
      type: Number,
    },
    currentPageData: {
      type: Object,
    },
    backgroundColor: {
      type: String,
    },
    tarotBoxHistory: {
      type: Object,
    },
    currentChapterIndex: {
      type: Number,
    },
    isShare: {
      type: Boolean,
    },
    question: {
      type: String,
    },
    requestSuccessful: {
      type: Boolean,
    },
    totalExtraAnswerCount: {
      type: Number,
    },
    currentAnswerIndex: {
      type: Number,
    },
    isInterpreting: {
      type: Boolean,
    },
    lastAskedQuestion: {
      type: String,
    },
    userData: {
      type: Object,
    },
    extraQuestionStatus: {
      type: String,
    },
  },
  emits: ['open-card-info', 'hide-bottom', 'show-bottom', 'update:question', 'request', 'request-more', 'open-ratings-popup'],
  data() {
    return {
      textLength: 0,
      isWriting: false,
      guideTexts: ['풀이는 어떠셨나요?', '아직 해소되지 않은 고민이 남아있나요?'],
      currentGuideTextIndex: 0,
      isAnimationPlayed: false,
      isQuestionInitiated: false,

      isPopupShown: false,
      isProceedPopup: false,
      isButtonEnabled: false,
    };
  },
  watch: {
    question() {
      this.$nextTick(() => {
        this.resizeTextarea();
      });
    },
    textLength(newValue) {
      if (newValue > 0) {
        this.enableButton();
      } else {
        this.isButtonEnabled = false;
      }
    },
  },
  computed: {
    formattedContent() {
      let content = this.currentPageData.page_content.extra_answer;

      // currentAnswerIndex가 1보다 크면 첫 문단 제거
      if (this.currentAnswerIndex > 1) {
        const firstParagraphEndIndex = content.indexOf('\n\n');
        if (firstParagraphEndIndex !== -1) {
          content = content.substring(firstParagraphEndIndex + 2);
        }
      }

      content = content.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
      content = content.replace(/\*(.*?)\*/g, '<em>$1</em>');
      content = marked(content);

      // 변환된 HTML 파싱
      const tempElement = document.createElement('div');
      tempElement.innerHTML = content;

      // 모든 <p> 요소에 gray4 클래스 추가
      const paragraphs = tempElement.getElementsByTagName('p');
      for (let i = 0; i < paragraphs.length; i++) {
        paragraphs[i].classList.add('gray4');
      }

      // '|END|' 텍스트 제거
      const endTags = tempElement.getElementsByTagName('p');
      for (let i = 0; i < endTags.length; i++) {
        if (endTags[i].textContent.includes('|END|')) {
          endTags[i].textContent = endTags[i].textContent.replace('|END|', '');
        }
      }

      // 수정된 HTML 반환
      return tempElement.innerHTML;
    },
    padding() {
      return this.isShare ? '48px 24px 200px 24px' : '110px 24px 200px 24px';
    },
    placeholderText() {
      if (this.isInterpreting && this.lastAskedQuestion) {
        return this.lastAskedQuestion;
      } else {
        return "예시)\n- 미래 연인이 보는 내 첫 인상은?\n- 전 남친이 연락하면 어떻게 답장할까?";
      }
    }
  },
  async mounted() {
    this.$nextTick(() => {
      logEvent ('result_extra_answer_into', {});
    });
    const urlParams = new URLSearchParams(window.location.search);
    const historyId = urlParams.get('history_id');
    const isShare = urlParams.get('is_share');
    if(!isShare){
      if(this.tarotBoxHistory.extra_question_status === 'new' || this.tarotBoxHistory.extra_question_status === 'paid_new'){
       await markReadExtraAnswer(historyId);
      }
    }
    if(this.currentAnswerIndex !== 1){
      logEvent('result_extra_answer_paid_into', {});
    } else {
      logEvent('result_extra_answer_free_into', {});
    }
    this.$nextTick(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.$emit('open-ratings-popup')
            logEvent('more_question_visible', {});
            observer.disconnect();
          }
        });
      }, {
        threshold: 0.7
      });

      // ref를 사용하여 요소 접근
      if (this.$refs.moreQuestion) {
        observer.observe(this.$refs.moreQuestion);
      }
    });
  },
  methods: {
    updateTextLength(event) {
      if (this.isQuestionInitiated === false) {
        this.isQuestionInitiated = true;
        logEvent('result_extra_question_input', {});
      }
      this.textLength = event.target.value.length;
    },
    openRequestPopup() {
      console.log('open')
      this.isPopupShown = true;
    },
    preventClick(event) {
      if (this.isInterpreting) {
        event.preventDefault();
      }
    },
    handleFocus() {
      this.$emit('hide-bottom')
      this.isWriting = true;
    },
    handleButtonClick() {
      if (this.isButtonEnabled && !this.isInterpreting) {
        logEvent('result_extra_request_paid_click', {});
        this.openRequestPopup();
      }
    },
    handleInput(event) {
      let newValue = event.target.value;
      const sanitizedValue = this.sanitizeInput(newValue);
      if (newValue !== sanitizedValue) {
        event.target.value = sanitizedValue;
      }
      this.$emit('update:question', sanitizedValue);
      this.updateTextLength({ target: { value: sanitizedValue } });
      this.resizeTextarea();
    },
    sanitizeInput(input) {
      // 줄바꿈 문자를 공백으로 대체
      let sanitized = input.replace(/\r\n|\r|\n/g, ' ');

      // 허용된 특수문자 정의
      const allowedSpecialChars = '.,?!-()·ㅡㆍ ᆢ ㅡㅣ';
      sanitized = sanitized.replace(
          new RegExp(`[^\\sㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9${allowedSpecialChars}]`, 'g'),
          ''
      );
      sanitized = sanitized.replace(/\s+/g, ' ');
      sanitized = sanitized.replace(/['"]/g, '');
      if (sanitized.length > 200) {
        sanitized = sanitized.slice(0, 200);
      }
      return sanitized;
    },
    resizeTextarea() {
      const textarea = this.$refs.textareaRef;
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 'px';
      }
    },
    handleBlur() {
      this.sanitizeInput(this.question);
      this.$emit('show-bottom');
      this.isWriting = false;
      let trimmedValue = this.question.trim();
      if (trimmedValue !== this.question) {
        this.$emit('update:question', trimmedValue);
        this.updateTextLength({ target: { value: trimmedValue } });
        this.resizeTextarea();
      }
    },
    enableButton() {
      this.isButtonEnabled = true;
    },
    showPopup() {
      this.isPopupShown = true;
    },
    closePopup() {
      this.isPopupShown = false;
    }
  },
};
</script>

<style scoped>

p{
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 26px !important;
  .gray4{
    font-size: 16px !important;
  }
}

.component-container {
  width: 100vw;
  position: relative;
  min-height: 100vh;
  background-size: 100% auto;
  background-position: bottom;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: auto;
}

.extra-listings{
  width: 100%;
  margin-top: 38px;
}


.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: auto;
}

.heading-texts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 6px;
}

#symbol {
  height: 30px;
}

.content-texts {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  line-height: 26px;
  box-sizing: border-box;
}

.questions {
  width: 100%;
  padding: 32px 10px 32px 10px;
  box-sizing: border-box;
  border-top: 1px solid var(--bdGray9);
  position: relative;
}

.questions::before,
.questions::after,
.questions::before,
.questions::after {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  background-color: var(--bdGray9);
  border: 1px solid var(--bdGray9);
  transform: rotate(45deg);
}

.questions::before {
  top: -3px;
  left: -3px;
}

.questions::after {
  top: -3px;
  right: -3px;
}

.questions::before {
  bottom: -3px;
  left: -3px;
}

.questions::after {
  bottom: -3px;
  right: -3px;
}


/* 추가 질문에 대한 스타일 */
.more-question{
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding-top: 40px;
  border-top: 1px solid var(--bdGray9);
  position: relative;
}

.more-question::before,
.more-question::after,
.more-question::before,
.more-question::after {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  background-color: var(--bdGray9);
  border: 1px solid var(--bdGray9);
  transform: rotate(45deg);
}

.more-question::before {
  top: -3px;
  left: -3px;
}

.more-question::after {
  top: -3px;
  right: -3px;
}

.more-question::before {
  bottom: -3px;
  left: -3px;
}

.more-question::after {
  bottom: -3px;
  right: -3px;
}

.actual-text{
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
}

.actual-title-text{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.actual-sub-text{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  width: 100%;
  box-sizing: border-box;
}

.text-field{
  position: relative;
  width: 100%;
  min-height: 120px;
  margin: 20px 0;
  box-sizing: border-box;
  border-radius: 8px;
  text-align: left;
  color: var(--gray3);
}

.text-field::placeholder {
  color: var(--gray7);
  font-size: 15px;
}

.text-field textarea{
  margin: 0;
  padding: 20px;
  width: 100%;
  line-height: 24px;
  background: var(--textareafield);
  outline: 1px solid var(--gray10);
}

.text-length{
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-size: 14px;
  color: var(--gray3);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s, transform 1s;
  -webkit-transition: opacity 1s, transform 1s; /* Safari 전용 */

}

.fade-enter-from {
  opacity: 0;
  transform: translateY(20px);
  -webkit-transform: translateY(20px); /* Safari 전용 */

}

.fade-leave-to {
  opacity: 0;
  transform: translateY(-20px);
  -webkit-transform: translateY(-20px); /* Safari 전용 */

}

.move-up {
  transform: translateY(-35px);
  transition: transform 0.5s;
}

.move-up2 {
  transform: translateY(-45px);
  transition: opacity 0s, transform 0s;
  transition-delay: 0s;
  margin-bottom: 20px;
  padding: 0;
  width: calc(100% - 40px);

}

.no-opacity-transition {
  transform: translateY(-45px);
  transition: opacity 0s, transform 0s;
  transition-delay: 0s;
}

.request-button{
  width: 100%;
  padding: 14px 0;
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  font-size: 18px;
  font-weight: bold;
  transition: transform 0.3s ease;
}

.text-field textarea:disabled {
  background-color: transparent;
  cursor: default;
}

.request-button.interpreting {
  background-color: var(--buttonProcessing);
  cursor: default;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(calc(50vh));
  opacity: 0;
}

.click-next-block{
  position: absolute;
  width: 60px;
  height: 60px;
  background: transparent;
  bottom: 5px;
  right: 10px;
  z-index: 100;
}

.request-button.disabled {
  cursor: not-allowed;
}

</style>