<template>
  <IntersectionObserverComp v-on:triggerFadeIn="fadeIn">
    <transition v-on:enter="enter">
      <div v-if="isShow" ref="thumbnailWrapper" class="fade-in-wrapper">
        <div class="thumbnail-board-list">
          <TitleWithIcon :title="sectionData.title" @click="handleMoreClick"/>
          <div class="thumbnail-grid">
            <ThumbnailImageDynamic
                v-for="(url, index) in thumbnailUrls"
                :key="sectionData.tarotBoxIds[index]"
                :src="url"
                :image-width="thumbnailImageWidth"
                @click="handleCardClick(sectionData.tarotBoxIds[index])"/>
          </div>
        </div>
      </div>
    </transition>
  </IntersectionObserverComp>
</template>

<script>
import TitleWithIcon from "@/tarot_box/pages/home/components/common/title/TitleWithIcon.vue";
import { mapGetters } from "vuex";
import ThumbnailImageDynamic from "@/tarot_box/pages/home/components/common/thumbnail_image/ThumbnailImageDynamic.vue";
import { logEvent } from "@/tarot_box/helper/native_api";
import IntersectionObserverComp from "@/common/IntersectionObserverComp.vue";

export default {
  name: "ThumbnailBoardList",
  components: {IntersectionObserverComp, ThumbnailImageDynamic, TitleWithIcon},
  inject: ["eventBus"],
  computed: {
    ...mapGetters(['tarotBoxes']),
    thumbnailUrls() {
      return this.sectionData.tarotBoxIds.slice(0, this.previewTarotBoxCount).map(boxId => {
        const box = this.tarotBoxes.find(box => box.id === boxId);
        return box ? box.thumbnailImgUrl : 'https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/thumbnail_default.jpg?alt=media&token=cbd4c705-25c2-467c-a105-c48e0687e3a2';
      });
    },
    thumbnailImageWidth() {
      return `${(this.windowWidth - 32 - 10) / 2}px`;
    }
  },
  props: {
    sectionData: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      previewTarotBoxCount: 4,
      windowWidth: window.innerWidth,
      isShow: false,
    };
  },
  methods: {
    handleMoreClick() {
      this.eventBus.emit('openCategoryPage', this.sectionData.id);
    },
    handleCardClick(boxId) {
      this.eventBus.emit('openTarotDetail', boxId);
      const box = this.tarotBoxes.find(box => box.id === boxId);
      const boxTitle = box ? box.title : '';
      logEvent('home_contents_click', {
        "tarot_box_id": boxId,
        "tarot_box_name": boxTitle,
        "click_origin_name": this.sectionData.title,
        "click_origin": "home_section_card"
      });
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    enter(el) {
      el.style.opacity = 0;
    },
    fadeIn() {
      this.isShow = true; // 요소를 렌더링
      this.$nextTick(() => { // DOM 업데이트 후 실행
        const wrapper = this.$refs.thumbnailWrapper;
        if (wrapper) {
          wrapper.style.opacity = 0; // 초기 상태 설정
          wrapper.style.transition = "opacity 1s"; // 트랜지션 설정
          wrapper.style.opacity = 1; // fade-in 애니메이션 실행
        }
      });
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style scoped>
.fade-in-wrapper {
  opacity: 0; /* 초기 상태 */
  transition: opacity 1s; /* 트랜지션 */
}
.thumbnail-board-list {
  width: 100dvw;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  padding: 0 16px;
}

.thumbnail-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  gap: 10px;
  overflow-x: scroll;
}
</style>
