<template>
  <div class="page-container no-bounce" ref="scrollContainer"
       :style="{
       backgroundImage: tarotBoxHistory && tarotBoxHistory.output_page_meta_data ? `url(${tarotBoxHistory.output_page_meta_data.background_image_url})` : 'none'
     }"
  >
  <TitlePage
       v-if="currentPageData && currentPageData.page_type === 'title_page' && currentPageData.page_index === currentIndex"
       :current-page-data="currentPageData"
       :tarot-box-history="tarotBoxHistory"
       :current-chapter-index="currentChapterIndex"
       :background-color="backgroundColor"/>
    <keep-alive>
      <TarotSpread
          v-if="currentPageData && currentPageData.page_type === 'tarot_spread' && currentPageData.page_index === currentIndex"
          :current-page-data="currentPageData"
          :tarot-box-history="tarotBoxHistory"
          :current-chapter-index="currentChapterIndex"
          :background-color="backgroundColor"
          :current-index="currentIndex"
          :is-share="isShare"
          @open-card-info="openCardInfo"
      />
    </keep-alive>
    <SajuSipsung
      v-if="currentPageData && currentPageData.page_type === 'saju_sipsung' && currentPageData.page_index === currentIndex"
      :current-page-data="currentPageData"
      :tarot-box-history="tarotBoxHistory"
      :current-chapter-index="currentChapterIndex"
      :user-name="userName"
      :is-share="isShare"
      @open-sipsung-info="openSipsungInfo"
      :background-color="backgroundColor"/>
    <AnswerComponent
      v-else-if="currentPageData && currentPageData.page_type === 'answer' && currentPageData.page_index === currentIndex"
      :current-page-data="currentPageData"
      :tarot-box-history="tarotBoxHistory"
      :current-chapter-index="currentChapterIndex"
      :is-share="isShare"
      :background-color="backgroundColor"/>
    <RecommendComponent
      v-else-if="currentPageData && currentPageData.page_type === 'recommend' && currentPageData.page_index === currentIndex"
      :current-page-data="currentPageData"
      :is-share="isShare"
      :tarot-box-history="tarotBoxHistory"
      :current-chapter-index="currentChapterIndex"
      :background-color="backgroundColor"/>
    <extraQuestion
      v-else-if="currentPageData && currentPageData.page_type === 'extra_question' && currentPageData.page_index === currentIndex"
      :is-animation-done="isAnimationDone"
      :is-share="isShare"
      :current-page-data="currentPageData"
      :current-chapter-index="currentChapterIndex"
      :background-color="backgroundColor"
      :question="question"
      :last-asked-question="lastAskedQuestion"
      :request-successful="requestSuccessful"
      :is-interpreting="isInterpreting"
      @hide-bottom="$emit('hide-bottom')"
      @show-bottom="$emit('show-bottom')"
      @animation-done="$emit('animation-done')"
      @request="$emit('request')"
      @next-pages="$emit('next-pages')"
      @update:question="$emit('update:question', $event)"
    />
    <ExtraAnswerComponent
      ref="extraAnswerComponent"
      v-else-if="currentPageData && currentPageData.page_type === 'extra_answer' && currentPageData.page_index === currentIndex"
      :current-page-data="currentPageData"
      :tarot-box-history="tarotBoxHistory"
      :current-chapter-index="currentChapterIndex"
      :is-share="isShare"
      :question="question"
      :last-asked-question="lastAskedQuestion"
      :total-extra-answer-count="totalExtraAnswerCount"
      :current-answer-index="currentAnswerIndex"
      :request-successful="requestSuccessful"
      :is-interpreting="isInterpreting"
      :extra-question-status="extraQuestionStatus"
      :user-data="userData"
      @open-ratings-popup="$emit('open-ratings-popup')"
      @hide-bottom="$emit('hide-bottom')"
      @show-bottom="$emit('show-bottom')"
      @update:question="$emit('update:question', $event)"
      @request-more="$emit('request-more')"
      :background-color="backgroundColor"/>
  </div>

</template>

<script>
import TitlePage from "@/tarot_box/pages/box_result_v2/components/OutputDetails/TitlePage.vue";
import TarotSpread from "@/tarot_box/pages/box_result_v2/components/OutputDetails/TarotSpread.vue";
import SajuSipsung from "@/tarot_box/pages/box_result_v2/components/OutputDetails/SajuSipsung.vue";
import AnswerComponent from "@/tarot_box/pages/box_result_v2/components/OutputDetails/AnswerComponent.vue";
import ExtraAnswerComponent from "@/tarot_box/pages/box_result_v2/components/OutputDetails/ExtraAnswerComponent.vue";
import RecommendComponent from "@/tarot_box/pages/box_result_v2/components/OutputDetails/RecommendComponent.vue";
import extraQuestion from "@/tarot_box/pages/box_result_v2/components/OutputDetails/extraQuestion.vue";

export default {
  name: "OutputDetails",
  components: {
    extraQuestion,
    RecommendComponent,
    SajuSipsung,
    TitlePage,
    TarotSpread,
    AnswerComponent,
    ExtraAnswerComponent
  },
  emits: [
    'open-card-info',
    "open-sipsung-info",
    'tarot-spread-open',
    'saju-sipsung-open',
    'animation-done',
    'update:question',
    'request',
    'request-more',
    'hide-bottom',
    'show-bottom',
    'next-pages',
    'user-update',
    'open-ratings-popup'
  ],
  watch: {
    currentPageData(newPageData) {
      if (newPageData) {
        if (newPageData.page_type === 'tarot_spread') {
          this.$emit('tarot-spread-open');
        } else if (newPageData.page_type === 'saju_sipsung') {
          this.$emit('saju-sipsung-open');
        }
      }
    }
  },
  computed: {
    currentPageData() {
      if (this.tarotBoxHistory && this.tarotBoxHistory.result_page_data) {
        return this.tarotBoxHistory.result_page_data.find(
            (page) => page.page_index === this.currentIndex
        );
      }
      return null; // 또는 적절한 기본값 반환
    },
    currentChapterIndex() {
      if (this.currentPageData) {
        const tableOfContents = this.tarotBoxHistory.result_page_data[0].page_content;
        const chapterIndex = tableOfContents.findIndex(
            (chapter) => chapter.page_title === this.currentPageData.page_title
        );
        return chapterIndex !== -1 ? chapterIndex + 1 : null;
      }
      return null;
    },
    openCardInfo() {
      return (cardId) => {
        this.$emit('open-card-info', cardId);
      };
    },
    openSipsungInfo() {
      return (sipsungInfo) => {
        this.$emit('open-sipsung-info', sipsungInfo);
      };
    },
  },
  methods: {
    scrollToTop() {
      const scrollContainer = this.$refs.scrollContainer;
      if (scrollContainer) {
        scrollContainer.scrollTo({ top: 0 });
      }
    },
  },
  props: {
    currentIndex: {
      type: Number,
    },
    tarotBoxHistory: {
      type: Object,
    },
    selectedCardData: {
      type: Object,
    },
    backgroundColor: {
      type: String,
    },
    userName: {
      type: String,
    },
    userData: {
      type: Object,
    },
    isShare: {
      type: Boolean,
    },
    isAnimationDone: {
      type: Boolean,
    },
    question: {
      type: String,
    },
    requestSuccessful: {
      type: Boolean,
    },
    totalExtraAnswerCount: {
      type: Number,
    },
    currentAnswerIndex: {
      type: Number,
    },
    isInterpreting:{
      type: Boolean,
    },
    lastAskedQuestion: {
      type: String,
    },
    extraQuestionStatus: {
      type: String,
    },
  }
}
</script>

<style scoped>
.page-container {
  overflow-y: auto;
  height: 100dvh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: auto;
}
</style>